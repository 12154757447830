import { TimeOption } from '@grafana/data';

import { ComboboxOption } from '../Combobox/Combobox';

export const quickOptions: TimeOption[] = [
  /*{ from: 'now-5m', to: 'now', display: 'Son 5 minutes' },
  { from: 'now-15m', to: 'now', display: 'Son 15 minutes' },
  { from: 'now-30m', to: 'now', display: 'Son 30 minutes' },
  { from: 'now-1h', to: 'now', display: 'Son 1 hour' },
  { from: 'now-3h', to: 'now', display: 'Son 3 hours' },
  { from: 'now-6h', to: 'now', display: 'Son 6 hours' },
  { from: 'now-12h', to: 'now', display: 'Son 12 hours' },
  { from: 'now-24h', to: 'now', display: 'Son 24 hours' },
  { from: 'now-2d', to: 'now', display: 'Son 2 days' },
  { from: 'now-7d', to: 'now', display: 'Son 7 days' },
  { from: 'now-30d', to: 'now', display: 'Son 30 days' },
  { from: 'now-90d', to: 'now', display: 'Son 90 days' },
  { from: 'now-6M', to: 'now', display: 'Son 6 months' },
  { from: 'now-1y', to: 'now', display: 'Son 1 year' },
  { from: 'now-2y', to: 'now', display: 'Son 2 years' },
  { from: 'now-5y', to: 'now', display: 'Son 5 years' },
  { from: 'now-1d/d', to: 'now-1d/d', display: 'Yesterday' },
  { from: 'now-2d/d', to: 'now-2d/d', display: 'Day before yesterday' },
  { from: 'now-7d/d', to: 'now-7d/d', display: 'This day Son week' },
  { from: 'now-1w/w', to: 'now-1w/w', display: 'Previous week' },
  { from: 'now-1M/M', to: 'now-1M/M', display: 'Previous month' },
  { from: 'now-1Q/fQ', to: 'now-1Q/fQ', display: 'Previous fiscal quarter' },
  { from: 'now-1y/y', to: 'now-1y/y', display: 'Previous year' },
  { from: 'now-1y/fy', to: 'now-1y/fy', display: 'Previous fiscal year' },
  { from: 'now/d', to: 'now/d', display: 'Today' },
  { from: 'now/d', to: 'now', display: 'Today so far' },
  { from: 'now/w', to: 'now/w', display: 'This week' },
  { from: 'now/w', to: 'now', display: 'This week so far' },
  { from: 'now/M', to: 'now/M', display: 'This month' },
  { from: 'now/M', to: 'now', display: 'This month so far' },
  { from: 'now/y', to: 'now/y', display: 'This year' },
  { from: 'now/y', to: 'now', display: 'This year so far' },
  { from: 'now/fQ', to: 'now', display: 'This fiscal quarter so far' },
  { from: 'now/fQ', to: 'now/fQ', display: 'This fiscal quarter' },
  { from: 'now/fy', to: 'now', display: 'This fiscal year so far' },
  { from: 'now/fy', to: 'now/fy', display: 'This fiscal year' },*/
  { from: 'now-5m', to: 'now', display: 'Son 5 dakika' },
  { from: 'now-15m', to: 'now', display: 'Son 15 dakika' },
  { from: 'now-30m', to: 'now', display: 'Son 30 dakika' },
  { from: 'now-1h', to: 'now', display: 'Son 1 saat' },
  { from: 'now-3h', to: 'now', display: 'Son 3 saat' },
  { from: 'now-6h', to: 'now', display: 'Son 6 saat' },
  { from: 'now-12h', to: 'now', display: 'Son 12 saat' },
  { from: 'now-24h', to: 'now', display: 'Son 24 saat' },
  { from: 'now-2d', to: 'now', display: 'Son 2 gün' },
  { from: 'now-7d', to: 'now', display: 'Son 7 gün' },
  { from: 'now-30d', to: 'now', display: 'Son 30 gün' },
  { from: 'now-90d', to: 'now', display: 'Son 90 gün' },
  { from: 'now-6M', to: 'now', display: 'Son 6 ay' },
  { from: 'now-1y', to: 'now', display: 'Son 1 yıl' },
  { from: 'now-2y', to: 'now', display: 'Son 2 yıl' },
  { from: 'now-5y', to: 'now', display: 'Son 5 yıl' },
  { from: 'now-1d/d', to: 'now-1d/d', display: 'Dün' },
  { from: 'now-2d/d', to: 'now-2d/d', display: 'Dünden önceki gün' },
  { from: 'now-7d/d', to: 'now-7d/d', display: 'Geçen hafta bugün' },
  { from: 'now-1w/w', to: 'now-1w/w', display: 'Önceki hafta' },
  { from: 'now-1M/M', to: 'now-1M/M', display: 'Önceki ay' },
  { from: 'now-1Q/fQ', to: 'now-1Q/fQ', display: 'Önceki finansal çeyrek' },
  { from: 'now-1y/y', to: 'now-1y/y', display: 'Önceki yıl' },
  { from: 'now-1y/fy', to: 'now-1y/fy', display: 'Önceki finansal yıl' },
  { from: 'now/d', to: 'now/d', display: 'Bugün' },
  { from: 'now/d', to: 'now', display: 'Şu ana kadar bugün' },
  { from: 'now/w', to: 'now/w', display: 'Bu hafta' },
  { from: 'now/w', to: 'now', display: 'Şu ana kadar hafta' },
  { from: 'now/M', to: 'now/M', display: 'Bu ay' },
  { from: 'now/M', to: 'now', display: 'Şu ana kadar ay' },
  { from: 'now/y', to: 'now/y', display: 'Bu yıl' },
  { from: 'now/y', to: 'now', display: 'Şu ana kadar yıl' },
  { from: 'now/fQ', to: 'now', display: 'Şu ana kadar finansal çeyrek' },
  { from: 'now/fQ', to: 'now/fQ', display: 'Bu finansal çeyrek' },
  { from: 'now/fy', to: 'now', display: 'Şu ana kadar finansal yıl' },
  { from: 'now/fy', to: 'now/fy', display: 'Bu finansal yıl' },
];

export const monthOptions: Array<ComboboxOption<number>> = [
  /*{ label: 'January', value: 0 },
  { label: 'February', value: 1 },
  { label: 'March', value: 2 },
  { label: 'April', value: 3 },
  { label: 'May', value: 4 },
  { label: 'June', value: 5 },
  { label: 'July', value: 6 },
  { label: 'August', value: 7 },
  { label: 'September', value: 8 },
  { label: 'October', value: 9 },
  { label: 'November', value: 10 },
  { label: 'December', value: 11 },*/
  { label: 'Ocak', value: 0 },
  { label: 'Şubat', value: 1 },
  { label: 'Mart', value: 2 },
  { label: 'Nisan', value: 3 },
  { label: 'Mayıs', value: 4 },
  { label: 'Haziran', value: 5 },
  { label: 'Temmuz', value: 6 },
  { label: 'Ağustos', value: 7 },
  { label: 'Eylül', value: 8 },
  { label: 'Ekim', value: 9 },
  { label: 'Kasım', value: 10 },
  { label: 'Aralık', value: 11 },

];
